import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subscription } from 'rxjs';
import { AccountService } from '../../../account/services';
import { AccountFacade } from '../../../account/store/facade';
import { DidsWithLabel, SessionDTO, SipCredentials } from '../../../account/store/states-models';
import { CallHistoryRecord, CallHistoryRecordType, DatabaseService, SipService, PresenceModel, AddressbookRecord, ADDRESSBOOK_TYPES } from '../../../core/services';
import { NotificationService } from '../../../core/services/notifications';
import { StartCallOutputType } from './start-call-modal.model';
import { PresenceService } from '../../../core/services/sip/presence.service';
import { take } from 'rxjs/operators';

export interface StartCallContactModel {
  fullname: string;
  number: string;
  originalContact: any;
}

@Component({
  selector: 'start-call-modal',
  templateUrl: './start-call-modal.component.html',
  styleUrls: [
    '../../styles/contact-list/contact-list.scss',
    './start-call-modal.component.scss'
  ]
})
export class StartCallModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  private numberRegex = /(^\+)?\d+/;
  private deviceUsername: string;

  public isNewCall: boolean = true;
  public startingValue: string;

  public currentOutboundNumber: DidsWithLabel;
  public outboundDidList: DidsWithLabel[];

  public currentDigitedValue: string = '';
  public isValidNumber: boolean = false;
  public showDialer: boolean = false;
  public searchResults: StartCallContactModel[] = [];
  public recents: CallHistoryRecord[] = [];

  private contacts: any[] = [];

  public outboundNumberLoading: boolean = false;
  public initLoading: boolean = false;
  public searchLoading: boolean = false;

  private presenceList: PresenceModel[];

  constructor(
    private modalRef: NgbActiveModal,
    private accountFacade: AccountFacade,
    private accountService: AccountService,
    private dbService: DatabaseService,
    private notificationService: NotificationService,
    private presenceService: PresenceService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.accountFacade.session$.subscribe((session: SessionDTO) => {
        this.outboundDidList = session.company_did_numbers_with_labels;
        this.currentOutboundNumber = this.outboundDidList.find((v: DidsWithLabel) => v.number === session.active_outbound_number);
      })
    );

    this.subscriptions.push(
      this.accountFacade.sipCredentials$.subscribe((creds: SipCredentials) => {
        this.deviceUsername = creds.username;
      })
    );
    this.presenceService.presenceSubscriptions$.pipe(take(1)).subscribe(p => this.presenceList = p);
    this.subscriptions.push(
      this.presenceService.presenceSubscription$.subscribe((presence: PresenceModel) => {
        this.contacts.find(c => {
          if(c.presenceId && c.presenceId === presence.entity) {
            c.status = presence.status;
          }
        });
        this.searchResults.find(c => {
          if(c.originalContact.presenceId && c.originalContact.presenceId === presence.entity) {
            c.originalContact.status = presence.status;
          }
        })
      })
    );

    this.initLoading = true;
    forkJoin(
      {
        records: this.dbService.getAllAddressbookRecords(),
        recents: this.dbService.getSomeCallHistoryRecordsByDateWithTypes(5, [CallHistoryRecordType.OUTGOING_ANSWERED, CallHistoryRecordType.OUTGOING_MISSED], undefined, false, true)
      }
    ).subscribe((res: {
      records: AddressbookRecord[],
      recents: CallHistoryRecord[]
    }) => {
      this.contacts = [...res.records];
      this.subscribeInternalUsersToPresence(this.contacts);
      this.recents = res.recents;
      if(this.currentDigitedValue) this.onInputChange();
      this.initLoading = false;
    }, (error) => {
      this.initLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public close(): void {
    this.modalRef.close();
  }

  public onOpenOutboundList(outbound: NgbDropdown): void {
    outbound.isOpen() ? outbound.close() : outbound.open();
  }

  public onSelectOutboundDid(did: DidsWithLabel): void {
    this.outboundNumberLoading = true;
    this.accountService.setOutboundNumber(did.number, this.deviceUsername).subscribe(() => {
      this.accountFacade.setOutboundNumber(did.number);
      this.currentOutboundNumber = did;
      this.outboundNumberLoading = false;
    }, () => {
      this.notificationService.error('start-call-modal.errors.update-outbound-failed')
      this.outboundNumberLoading = false;
    });
  }

  public onClearInput(): void {
    this.currentDigitedValue = '';
  }

  public onInputChange(): void {
    this.isValidNumber = this.numberRegex.test(this.currentDigitedValue);
    this.searchResults = this.contacts.filter(this.filterContacts, this).map(this.mapContacts, this).slice(0, 5);
  }


  private filterContacts(contact: AddressbookRecord): boolean {
    const searchTerm = this.currentDigitedValue.toLowerCase();
    return `${contact.firstName.toLowerCase()} ${contact.lastName?.toLowerCase()}`.includes(searchTerm) || contact.defaultNumber.number?.includes(searchTerm);
  }

  private mapContacts(contact: any): StartCallContactModel {
    return {
      fullname: contact.lastName ? `${contact.firstName} ${contact.lastName}` : `${contact.firstName}`,
      number: contact.defaultNumber?.number,
      originalContact: contact
    }
  }

  public onShowDial(): void {
    this.showDialer = true;
  }

  public onShowContacts(): void {
    this.showDialer = false;
  }

  public onDialerKeyPressed(event: string): void {
    this.currentDigitedValue += event;
    this.onInputChange();
  }

  public callFromRecents(recent: CallHistoryRecord): void {
    this.modalRef.close({
      contact: recent,
      type: StartCallOutputType.EVENT
    })
  }
  public callFromContacts(contact: AddressbookRecord): void {
    let type: StartCallOutputType;
    switch(contact.type) {
      case ADDRESSBOOK_TYPES.EXTERNAL: {
        type = StartCallOutputType.EXTERNAL_VCARD;
      }
      break;
      case ADDRESSBOOK_TYPES.INTERNAL_USER: {
        type = StartCallOutputType.INTERNAL_USER
      }
      break;
      case ADDRESSBOOK_TYPES.INTERNAL_GROUP: {
        type = StartCallOutputType.INTERNAL_GROUP
      }
    }
    this.modalRef.close({
      contact: contact,
      type: type
    })
  }
  public callFromNumber(number: string): void {
    this.modalRef.close({
      contact: null,
      type: StartCallOutputType.NUMBER,
      number: number
    })
  }

  private subscribeInternalUsersToPresence(users: AddressbookRecord[]) {
    users.forEach(u => {
      if(u.type === ADDRESSBOOK_TYPES.INTERNAL_USER){
        const idx = this.presenceList.findIndex(value => value.entity === u.presenceId);
        if(idx === -1) {
          this.presenceService.subscribeEntity(u.presenceId || u.defaultNumber.number);
        } else {
          u.status = this.presenceList[idx].status;
        }
      }
    });
  }
}
